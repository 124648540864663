import sanitizehtml from 'sanitize-html';
import { type DirectiveBinding } from 'vue';

const vSanitizehtml = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        el.innerHTML = sanitizehtml(binding.value);
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
        el.innerHTML = sanitizehtml(binding.value);
    },
};

export default vSanitizehtml;